<template>
  <!-- 库存管理 -->
  <div ref="stockManage" class="stockManage">
    <!-- 操作栏 -->
    <div class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">车商名称：</div>
        <div class="col-2">
          <el-select
            v-model="formData.carServiceProviderId"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in carDealerList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">车型分类名称：</div>
        <div class="col-2">
          <el-select
            v-model="formData.vehicleTypeDictCode"
            clearable
            placeholder="请选择"
            @change="TypeDictChange"
          >
            <el-option
              v-for="item in vehicleTypeDictList"
              :key="item.dictCode"
              :label="item.dictValue"
              :value="item.dictCode"
            ></el-option>
          </el-select>
        </div>
      </div>
      <!-- <div class="actionBar-box">
        <div class="col-1">子类：</div>
        <div class="col-2">
          <el-select
            v-model="formData.vehicleTypeChildName"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="item in childrenList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div> -->
      <div class="actionBar-box">
        <div class="col-1">当前库存小于：</div>
        <div class="col-2">
          <el-input
            v-model="formData.currentCarCountLt"
            clearable
            type="number"
          ></el-input>
        </div>
      </div>
      <!-- 按钮 -->
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="renderData(1)"
          >查询</el-button
        >
      </div>
    </div>
    <Table
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
      :col-class-type="true"
      operation-width="120"
      :highlight-current-row="true"
      :table-height="tableHeight"
    >
      <template slot-scope="scope">
        <el-button
          v-if="listFind('修改库存上限')"
          type="text"
          size="small"
          sort="primary"
          @click="updateData(scope.scopeRow)"
          >修改库存上限</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes,prev, pager, next, jumper"
        :total="total"
        :page-size="formData.pageSize"
        :current-page="formData.currentPage"
        @current-change="onCurrentChange"
        @size-change="onSizeChange"
      ></el-pagination>
    </div>
    <charterPopup
      v-if="show"
      :header-text="headerText"
      @confirmFun="confirmFun"
      @closePopupFun="clearForm"
    >
      <el-form
        ref="Form"
        :model="Form"
        :rules="rules"
        label-width="140px"
        label-position="right"
        class="demo-form dialog-form"
      >
        <el-form-item label="库存上限：" prop="allCarCount">
          <el-input
            v-model="Form.allCarCount"
            onkeyup="this.value=this.value.replace(/[^0-9]/g,'')"
          ></el-input>
        </el-form-item>
      </el-form>
    </charterPopup>
  </div>
</template>

<script>
import {
  carTypeListAPI,
  getVehicleTypeChildNameListAPI,
  charterInventoryRenderAPI,
  charterInventoryonShelfAPI,
  charterInventoryAllCarCountAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      charteredCarCount: null,
      headerText: "",
      show: false,
      Form: { allCarCount: 0, charteredVehicleModelId: "" },
      rules: {
        allCarCount: [
          {
            required: true,
            trigger: "blur",
            validator: (rule, value, callback) => {
              if (value === "") {
                callback("请输入库存上限");
              } else {
                if (value > 200) {
                  callback("库存上限不得大于200");
                } else {
                  if (value < this.charteredCarCount) {
                    callback("库存上限不得小于包车车辆数");
                  } else {
                    callback();
                  }
                }
              }
            },
          },
        ],
      },
      tableHeight: 0,
      carDealerList: [],
      vehicleTypeDictList: [],
      childrenList: [],
      tableData: [],
      total: 0,
      titleName: [
        {
          title: "所属车商",
          props: "carServiceProviderName",
        },
        {
          title: "车型分类名称",
          props: "vehicleTypeName",
          width: 120,
        },
        // {
        //   title: "子类",
        //   props: "vehicleTypeChildName",
        // },
        {
          title: "分类描述",
          props: "modelTypeDescription",
        },
        {
          title: "座位数",
          props: "seatNum",
        },
        {
          title: "库存上限",
          props: "allCarCount",
        },
        {
          title: "当前库存",
          props: "currentCarCount",
        },
        {
          title: "包车车辆数",
          props: "charteredCarCount",
        },
        {
          title: "是否上架",
          props: "onShelf",
          render: (h, params, index) => {
            return h("el-switch", {
              props: {
                value: params.onShelf === 0 ? false : true,
                "active-color": "#13ce66",
                "inactive-color": "#E3E3E3",
              },
              on: {
                change: (val) => {
                  console.log(params.onShelf);
                  charterInventoryonShelfAPI({
                    charteredVehicleModelId: params.id,
                    onShelf: params.onShelf === 0 ? 1 : 0,
                  }).then((res) => {
                    if (res.code == "SUCCESS") {
                      this.renderData();
                    }
                  });
                },
              },
            });
          },
        },
      ],
      formData: {
        currentPage: 1,
        pageSize: 10,
        carServiceProviderId: "", //车商ID
        vehicleTypeDictCode: "", //车型分类字典编码
        vehicleTypeChildName: "", //子类名称
        currentCarCountLt: "", //当前库存小于
      },
    };
  },
  created() {
    this.providerListFun().then((res) => {
      this.carDealerList = res;
    });
    // 获取车型分类名称下拉列表数据
    carTypeListAPI().then((res) => {
      if (res.code == "SUCCESS") {
        this.vehicleTypeDictList = res.data;
      }
    });
  },
  mounted() {
    this.renderData();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.stockManage.clientHeight;
      this.tableHeight = wholeHeight - 220;
    },
    // 弹窗确定按钮emit
    confirmFun() {
      this.$refs.Form.validate((valid) => {
        if (valid) {
          charterInventoryAllCarCountAPI(this.Form).then((res) => {
            if (res.code == "SUCCESS") {
              this.clearForm();
              this.renderData();
            }
          });
        }
      });
    },
    // 关闭弹窗emit
    clearForm() {
      this.show = false;
      this.Form = { allCarCount: 0, charteredVehicleModelId: "" };
      this.charteredCarCoun = null;
    },
    // 点击修改库存上线按钮
    updateData(scope) {
      this.headerText = "修改";
      this.show = true;
      this.Form.allCarCount = scope.allCarCount;
      this.Form.charteredVehicleModelId = scope.id;
      this.charteredCarCount = scope.charteredCarCount;
    },
    // 页码change事件
    onCurrentChange(page) {
      this.formData.currentPage = page;
      this.renderData();
    },
    // 当前条数改变
    onSizeChange(size) {
      this.formData.pageSize = size;
      this.formData.currentPage = 1;
      this.renderData();
      this.computeHeight();
    },
    // 表格数据获取
    renderData(currentPage) {
      this.formData.currentPage = currentPage || this.formData.currentPage;
      charterInventoryRenderAPI(this.formData).then((res) => {
        if (res.code == "SUCCESS") {
          this.tableData = res.data.list;
          this.total = res.data.total;
        }
      });
    },
    // 车型分类名称下拉列表change事件
    TypeDictChange(val) {
      if (val == "") {
        this.formData.vehicleTypeChildName = "";
        this.childrenList = [];
      } else {
        this.childrenListDataRender();
      }
    },
    // 子类下拉列表数据获取function
    childrenListDataRender() {
      getVehicleTypeChildNameListAPI({
        vehicleTypeDictCode: this.formData.vehicleTypeDictCode,
      }).then((res) => {
        if (res.code == "SUCCESS") {
          this.childrenList = res.data;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-input__inner {
  line-height: 20px;
}
.stockManage {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 32px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 106px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
</style>
